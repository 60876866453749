html{
    font-size: 1.5em;
    -webkit-overflow-scrolling: touch;
    overflow: hidden;
    
}
@media (max-width: 900px) {
    html{
        overflow-y: scroll;
    }
}
h1{
    color: #000;
    /* -webkit-text-stroke: 1px #FFF; */
    margin: 0;
    font-weight: bold;
    font-size: xx-large;
}
summary {
    display: block;
}
summary::-webkit-details-marker {
    display: none;
}
.pos-abs-bottom{
    position: absolute;
    /*上下中央*/
    bottom: 0%;
    left: 50%;
    /*移動自身的50%*/
    transform: translate(-50%, -50%);
}
.flex{
    display: flex;
}
input[type="number"]::-webkit-outer-spin-button, 
input[type="number"]::-webkit-inner-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
} 

div.LoginContainer{
    background-image: url(../img/login.png);
    height: 100vh;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}

div.InputArea{
    background-color: rgba(255, 255, 255, 0.7);
    width: 50vw;
    box-sizing: border-box;
    padding: 20px;
    display: flex;
    flex-direction: column;
}

div.ObjectContainer{
    height: 100vh;
}

p{
    margin: 0;
}

.Nature{
    background: url(../img/background_default.png);
}
.NatureNoneHome {
    background: url(../img/background_default_noneHome.png);
}
.Idol{
    background: url(../img/background_idol.png);
}
.IdolNoneHome {
    background: url(../img/background_idol_noneHome.png);
}
.Dragon{
    background: url(../img/background_dragon.png);
}
.DragonNoneHome {
    background: url(../img/background_dragon_noneHome.png);
}


.LandGrid{
    height: 95%;
    width: 95%;
    border-collapse: collapse;
}
.LandGrid td{
    /* border: 5px dotted #d0d3d8a6; */
}

.center-text{
    text-align: center;
}

.normal-button{
    background-color: #f8b127;
    border: 3px solid #548548;
    border-radius: 30px;
    padding: 5px 20px;
}

.text-nowrap{
    white-space: nowrap;
}