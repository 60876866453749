.fc-day-sun{
    background-color: #f19b9b1f;
    color: red;
}
.fc-day-sat{
    background-color: #9bcff11a;
    color:blue;
}
.fc-daygrid-day-events{
    width: 100%;
    height: 100%;
    position: absolute !important;
    top: 0;
}
.fc-daygrid-event-harness{
    position: absolute !important;
    width: 100%;
    height: 100%;
    top:0;
    background-image:url(/public/img/stamp.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    z-index: 9999;
}
.fc-event{
    display: none;
}
.fc-daygrid-day-top{
    display: block !important;
    text-align: left;
    font-size: small;
}
.fc-header-toolbar  {
    display: none !important;
}
.fc-theme-standard{
    background-color: #fff;
}